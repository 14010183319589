:root {
  --hero-background-image: url("./images/heroBackgroundImage.jpg");
  --hero-background-opacity: rgba(255, 255, 255, 0.8);
  --linekam-red: #D90429;
}

a {
  color: #ffffff !important;
}

td {
  background-color: transparent !important;
}

.hero-section {
  background-color: #ffffff;
  background-image: linear-gradient(var(--hero-background-opacity), var(--hero-background-opacity)), var(--hero-background-image);
  background-size: cover;
  padding: 150px 0px 100px 0px;
}

.app-screenshot {
  max-width: 60%;
  transition: transform 0.4s ease;
}

/* .app-screenshot:hover {
  z-index: 99;
  transform: scale(1.2);
} */

.app-store-badge {
  height: 50px;
}

.logo {
  max-width: 200px;
}

.nav-link:hover {
  /* text-decoration: underline; */
  color: #D90429 !important;
}